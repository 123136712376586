<template>
    <v-container fluid class="my-0 py-0">
        <v-row class="my-0 py-0" align="center" justify="center">
            <v-col cols="12" align="center">
                <img
                    height="auto"
                    width="100%"
                    :src="require(`@/assets/slide1.jpg`)"
                    class="rounded-lg"
                >
            </v-col>
        </v-row>
        <!-- <v-row>
            <v-col v-for="(article, index) in articles" :key="index" cols="12" md="6">
                <article-card :article="article" />
            </v-col>
        </v-row> -->
    </v-container>
</template>

<script>
    // import ArticleCard from '@/components/ArticleCard'
    // import articleService from '@/services/article'

    export default {
        // components: { ArticleCard },
        data() {
            return {
                page: 1,
                size: 7,
                totalPages: 0,
                articles: [],
                slides: [
                    'slide1',
                    'slide2',
                    'slide4',
                    'slide5',
                    'slide6'
                ]
            }
        },
        created() {
            this.fetchArticles()
        },
        mounted() {
            this.loadMore()
        },
        methods: {
            goToArticle(article) {
                this.$router.push({ name: 'Article', params: { id: article.id } })
            },
            getArticleImage() {
                return `${process.env.VUE_APP_GOOGLE_STORAGE_API}/${this.articles[0].image}`
            },
            loadMore() {
                this.fetchNextData()
            },
            fetchNextData() {
                window.onscroll = () => {
                    const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
                    if (bottomOfWindow) {
                        if (this.page < this.totalPages) {
                            this.page += 1
                            this.fetchArticles()
                        }
                    }
                }
            }
            // fetchArticles() {
            //     articleService.getArticles(this.page, this.size).then(resp => {
            //         this.articles.push(...resp.data.content)
            //         this.totalPages = resp.data.totalPages
            //     })
            // }
        }
    }
</script>

<style scoped>
.jumbotron-top {
    height: 65px;
}

.jumbotron {
    /* clip-path: polygon(0 0, 100% 1%, 96% 100%, 4% 100%); */
    background-color: white;
    height: 550px;
}

@media only screen and (max-width: 1000px) {
    .jumbotron {
        /* clip-path: polygon(0 0, 100% 1%, 96% 100%, 4% 100%); */
        background-color: white;
        height: 300px;
    }
}

.jumbotron-bottom {
    height: 55px;
    background-color: black;
}
</style>
